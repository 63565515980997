import React from "react"

export default function() {
  return (
    <section style={{margin: 0}}>
        <div
          className="col-md-12"
          style={{background: "#58b0da29",color: "#443636",paddingTop: "3em",paddingBottom: "2em"}}
        >
          <div style={{width: "100%"}}>
              <p>
              Sheer Analytics and Insights is a market research and consulting firm that provides customized
research solutions to businesses across various industries. The company offers a wide range of
services, including market research, industry analysis, business strategy consulting, and market
forecasting.              
              </p>
              <br />
              <h1 style={{fontWeight: 'bold', textDecoration: 'underline'}}>If you are interested in pursuing a career at Sheer Analytics and Insights, there are several roles
you can consider:</h1>
              <p><h3>Market Research Analyst:</h3> As a Market Research Analyst, you will be responsible for conducting
primary and secondary research, analyzing data, and preparing reports and presentations for clients.
You will need to have strong analytical skills, attention to detail, and the ability to work with large
amounts of data.</p>
            <p><h3>Business Development Manager:</h3> As a Business Development Manager, you will be responsible for
identifying potential clients, developing relationships with them, and pitching the company&#39;s
services. You will need to have excellent communication skills, a strong sales background, and the
ability to work independently.</p>
<p><h3>Consultant:</h3> As a consultant, you will be responsible for working closely with clients to understand
their needs, develop customized research solutions, and provide strategic advice. You will need to
have strong problem-solving skills, the ability to work well in a team, and excellent communication
skills.</p>
<p><h3>Data Analyst:</h3> As a Data Analyst, you will be responsible for collecting, analyzing, and interpreting
data to help clients make informed business decisions. You will need to have strong quantitative
skills, experience working with data analysis tools, and the ability to present findings in a clear and
concise manner.</p>
<p>To pursue a career at Sheer Analytics and Insights, you will need to have a relevant degree and
experience in market research, consulting, or a related field. The company offers a dynamic work
environment, competitive compensation, and opportunities for career growth and development.</p>
          </div>
          <p>Please mail your resume at <a href="mailto:hr@sheeranalyticsandinsights.com">hr@sheeranalyticsandinsights.com</a></p>
        </div>
    </section>
  )
}
